import en from './en';
import ko from './ko';

// var defaultLocale = "English";

// var lang = navigator.language
// if (lang == "ko") {
//     defaultLocale = "한국어"
// }

const languages = {
    English: en,
    "한국어": ko
}

export default languages

